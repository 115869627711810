














































































































import { mdiMicrosoftWindows, mdiApple } from '@mdi/js';
import Vue from 'vue';
import LoginCard from '@/components/specific/LoginCard.vue';
import settings from '@/settings';

export default Vue.extend({
  name: 'DownloadApp',
  components: {
    LoginCard,
  },
  data() {
    return {
      // 表示順序で記述
      /* eslint-disable vue/sort-keys */
      icons: {
        mdiMicrosoftWindows,
        mdiApple,
      },
      downloadedMS: false,
      downloadedApple: false,
      /* eslint-enable vue/sort-keys */
    };
  },
  computed: {
    macDlUrl() {
      return settings.agent.url.mac;
    },
    windowsDlUrl() {
      return settings.agent.url.win;
    },
  },
  methods: {
    getAppleApp() {
      this.downloadedMS = false;
      this.downloadedApple = true;
    },

    getMSApp() {
      this.downloadedApple = false;
      this.downloadedMS = true;
    },

    toHome() {
      this.$router.push('/');
    },

    toSetting() {
      this.$router.push('/signup/setting');
    },
  },
});
